const blockUserReasonsData = {
  code: 'ERCD0000',
  message: 'Запрос обработан успешно',
  data: [
    { id: '33716683-5b29-4f5c-ad15-ef18e26e31cf', description: 'Harassment' },
    {
      id: 'f4c1b48d-67c1-4431-b146-c73dd2cc56c2',
      description: 'Posting personal information'
    },
    {
      id: '7ecda253-f4f0-4e0a-9ec8-cd63b11ddc37',
      description: 'Impersonation'
    },
    {
      id: '9d975ee9-1ba6-4c20-9572-fa54302434f4',
      description: 'Fraudulent activity'
    },
    { id: '4bbbcd73-5245-4dc9-b4e8-44be66367b60', description: 'Spamming' },
    { id: 'acfea818-4227-4b31-bce9-5eaf4da2c361', description: 'Hate speech' },
    {
      id: 'b972b3b3-b397-4091-bc5b-e3aab89e406e',
      description: 'Impersonation'
    },
    {
      id: '9f95cca0-cca4-4d79-a0b3-3dfe724bd304',
      description: 'Inappropriate content'
    },
    {
      id: 'd7724635-eaa7-494c-a437-be1f8483a787',
      description: 'Spreading misinformation'
    },
    { id: '5631f871-8834-4c7f-95c6-9627cb672890', description: 'Impersonation' }
  ]
};

export default blockUserReasonsData;
