import React, { useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { Operator } from './operator'; // Ensure this file is named 'Operator.ts'

const ProfilesList: React.FC = () => {
  const [operators, setOperators] = useState<Operator[]>([]);
  const [selectedOperator, setSelectedOperator] = useState<Operator | null>(
    null
  );
  const [showForm, setShowForm] = useState(false);

  // Show form to add or edit an operator
  const addOperator = () => {
    setSelectedOperator(null);
    setShowForm(true);
  };

  const editOperator = (operator: Operator) => {
    setSelectedOperator(operator);
    setShowForm(true);
  };

  const deleteOperator = (operator: Operator) => {
    if (window.confirm(`Вы уверены, что хотите удалить ${operator.name}?`)) {
      // Use backticks for template literals
      setOperators(operators.filter(op => op !== operator));
    }
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const name = form.elements.namedItem('name') as HTMLInputElement;
    const profileType = form.elements.namedItem(
      'profileType'
    ) as HTMLInputElement;
    const group = form.elements.namedItem('group') as HTMLSelectElement;
    const roles = Array.from(
      // eslint-disable-next-line no-undef
      form.querySelectorAll(
        'input[name="roles"]:checked'
      ) as NodeListOf<HTMLInputElement>
    ).map(input => input.value);
    const enabled = (form.elements.namedItem('enabled') as HTMLInputElement)
      .checked;

    const newOperator: Operator = {
      name: name.value,
      profileType: profileType.value,
      group: group.value,
      enabled: enabled,
      roles: roles
    };

    if (selectedOperator) {
      // Update existing operator
      setOperators(
        operators.map(op => (op === selectedOperator ? newOperator : op))
      );
    } else {
      // Add new operator
      setOperators([...operators, newOperator]);
    }

    setShowForm(false);
  };

  return (
    <div className="profiles-page" style={{ padding: '20px' }}>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Операторы</h2>
        {!showForm && (
          <Button variant="primary" onClick={addOperator}>
            Добавить оператора
          </Button>
        )}
      </div>

      {!showForm && (
        <div
          style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px'
          }}
        >
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Оператор</th>
                <th>Тип профиля</th>
                <th>Включение</th>
                <th>Группа</th>
                <th>Роли</th>
                <th>Управление</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>ФИО</td>
                <td>Фин контроллер</td>
                <td>✓</td>
                <td>группа_для_Операторов</td>
                <td>Добавление Оператора</td>
                <td>
                  <Button variant="outline-secondary" size="sm">
                    ✏️
                  </Button>{' '}
                  <Button variant="outline-danger" size="sm">
                    🗑️
                  </Button>
                </td>
              </tr>
              {operators.map((operator, index) => (
                <tr key={index}>
                  <td>{operator.name}</td>
                  <td>{operator.profileType}</td>
                  <td>{operator.enabled ? '✔️' : '❌'}</td>
                  <td>{operator.group}</td>
                  <td>{operator.roles.join(', ')}</td>
                  <td>
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={() => editOperator(operator)}
                      style={{ marginRight: '5px' }}
                    >
                      ✏️
                    </Button>
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={() => deleteOperator(operator)}
                    >
                      🗑️
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      {showForm && (
        <div
          style={{
            backgroundColor: '#e7f3ff', // Light blue background to match the sketch
            padding: '20px',
            borderRadius: '8px',
            marginTop: '20px'
          }}
        >
          <h4>
            {selectedOperator
              ? 'Редактировать оператора'
              : 'Добавить оператора'}
          </h4>
          <Form onSubmit={handleFormSubmit}>
            <Row>
              <Col>
                <Form.Group controlId="operatorName">
                  <Form.Label>ФИО</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    defaultValue={selectedOperator ? selectedOperator.name : ''}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group controlId="profileType">
                  <Form.Label>Тип профиля</Form.Label>
                  <Form.Control
                    as="select"
                    name="profileType"
                    defaultValue={
                      selectedOperator ? selectedOperator.profileType : ''
                    }
                    required
                  >
                    <option value="Оператор">Оператор</option>
                    <option value="Фин. контролёр">Фин. контролёр</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group controlId="group">
                  <Form.Label>Группа</Form.Label>
                  <Form.Control
                    as="select"
                    name="group"
                    defaultValue={
                      selectedOperator ? selectedOperator.group : ''
                    }
                    required
                  >
                    <option value="группа_для_Операторов">
                      группа_для_Операторов
                    </option>
                    <option value="фин. операторы">фин. операторы</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group controlId="roles">
                  <Form.Label>Роли</Form.Label>
                  <div>
                    {[
                      'Добавление Оператора',
                      'Редактирование Оператора',
                      'Удаление Оператора'
                    ].map(role => (
                      <Form.Check
                        key={role}
                        type="checkbox"
                        name="roles"
                        value={role}
                        label={role}
                        defaultChecked={selectedOperator?.roles.includes(role)}
                      />
                    ))}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group controlId="enabled">
                  <Form.Label>Активация</Form.Label>
                  <Form.Check
                    type="switch"
                    name="enabled"
                    label="Активен"
                    defaultChecked={
                      selectedOperator ? selectedOperator.enabled : false
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-3">
              <Button
                variant="secondary"
                onClick={() => setShowForm(false)}
                className="me-2"
              >
                Назад
              </Button>
              <Button variant="primary" type="submit">
                Сохранить
              </Button>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

export default ProfilesList;
