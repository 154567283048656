const TRANSACTION_STATUSES: { [key: number]: string } = {
  1: 'В обработке',
  2: 'Выполнено',
  3: 'Отклонено'
};

const TRANSACTION_TYPES: { [key: number]: string } = {
  4: 'Обмен',
  2: 'Перевод',
  1: 'Депозит'
};

export { TRANSACTION_STATUSES, TRANSACTION_TYPES };
