import React, { useState } from 'react';
import { Button, Table } from 'react-bootstrap';

const Limits: React.FC = () => {
  const [visibleTable, setVisibleTable] = useState<string>('table1');

  const handleTableChange = (tableId: string) => {
    setVisibleTable(tableId);
  };

  return (
    <div className="Limits-page">
      <div
        className="d-flex justify-content-center mb-4"
        style={{ marginLeft: '350px' }}
      >
        {visibleTable === 'table1' && (
          <>
            <Button
              variant="primary"
              onClick={() => handleTableChange('table2')}
            >
              Добавить группа
            </Button>
            <Button
              variant="primary"
              className="mx-2"
              onClick={() => handleTableChange('table3')}
            >
              Добавить лимит
            </Button>
          </>
        )}
        {visibleTable === 'table2' && (
          <>
            <Button
              variant="primary"
              className="mx-2"
              onClick={() => handleTableChange('table3')}
            >
              Добавить лимит
            </Button>
            <Button
              variant="secondary"
              className="mx-2"
              onClick={() => handleTableChange('table1')}
            >
              Назад к главной таблице
            </Button>
          </>
        )}
        {visibleTable === 'table3' && (
          <>
            <Button
              variant="primary"
              className="mx-2"
              onClick={() => handleTableChange('table2')}
            >
              Добавить группа
            </Button>
            <Button
              variant="secondary"
              className="mx-2"
              onClick={() => handleTableChange('table1')}
            >
              Назад к главной таблице
            </Button>
          </>
        )}
      </div>

      <div
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px'
        }}
      >
        {visibleTable === 'table1' && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Название</th>
                <th>Группа</th>
                <th>Включение</th>
                <th colSpan={3}>Сумма</th>
                {''}
                <th>Управление</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Лимит_1</td>
                <td>Группа_1</td>
                <td>✓</td>
                <td>Операции</td>
                <td>День</td>
                <td>Неделя</td>
                <td>
                  <Button variant="outline-secondary" size="sm">
                    ✏️
                  </Button>{' '}
                  <Button variant="outline-danger" size="sm">
                    🗑️
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        )}

        {visibleTable === 'table2' && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Название</th>
                <th>Тип пользователей</th>
                <th>Страны</th>
                <th>Тип операций</th>
                <th>Скор балл</th>
                <th>Управление</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Группа_1</td>
                <td>Физ. лица</td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <Button variant="outline-secondary" size="sm">
                    ✏️
                  </Button>{' '}
                  <Button variant="outline-danger" size="sm">
                    🗑️
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        )}

        {visibleTable === 'table3' && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Название</th>
                <th colSpan={3}>По количеству</th>
                {''}
                <th colSpan={3}>По сумме</th>
                {''}
                <th>Валюта</th>
                <th>Управление</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Лимит_1</td>
                <td>Час</td>
                <td>День</td>
                <td>Неделя</td>
                <td>Час</td>
                <td>День</td>
                <td>Неделя</td>
                <td> </td>
                <td>
                  <Button variant="outline-secondary" size="sm">
                    ✏️
                  </Button>{' '}
                  <Button variant="outline-danger" size="sm">
                    🗑️
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default Limits;
