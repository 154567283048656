import React, { useState, useEffect } from 'react';
import ApiService from '../../../helpers/apiService';

import AccountsFilters from './AccountsFilters';
import AccountsTable from './AccountsTable';

const Accounts: React.FC = () => {
  const [filters, setFilters] = useState({
    search: '',
    currencies: '',
    from: '',
    to: ''
  });
  const [accounts, setAccounts] = useState<any>([]);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    countAll: 0
  });

  const fetchAccounts = async () => {
    setLoading(true);
    try {
      const response = await ApiService.searchAccounts({
        search: filters.search,
        currency: filters.currencies,
        dateFrom: filters.from,
        dateTo: filters.to,
        page: pagination.page,
        limit: pagination.limit
      });
      const data = response.data;

      if (filters.search.match(/^\d+$/)) {
        setAccounts([data.accounts[0]]);
        fetchTransactions(data.accounts[0].accountId);
      } else {
        setAccounts(data.accounts);
      }

      setPagination(prev => ({
        ...prev,
        countAll: data.total
      }));
    } catch (error) {
      console.error('Ошибка при загрузке счетов:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTransactions = async (accountId: string) => {
    setLoading(true);
    try {
      const response = await ApiService.getTransactions(accountId, {
        page: pagination.page,
        limit: pagination.limit
      });
      setTransactions(response.data.transactions);
      console.log(transactions);
    } catch (error) {
      console.error('Ошибка при загрузке транзакций:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterSubmit = () => {
    setPagination(prev => ({
      ...prev,
      page: 1 // Сбрасываем на первую страницу при новом поиске
    }));
    fetchAccounts();
  };

  const handlePageChange = (page: number) => {
    setPagination(prev => ({
      ...prev,
      page
    }));
  };

  useEffect(() => {
    fetchAccounts();
  }, [pagination.page]);

  return (
    <div>
      <AccountsFilters
        values={filters}
        setFieldValue={(field, value) =>
          setFilters(prev => ({ ...prev, [field]: value }))
        }
        handleSubmit={handleFilterSubmit}
        loading={loading}
      />
      <AccountsTable
        accounts={accounts}
        // transactions={transactions}
        pagination={pagination}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default Accounts;
