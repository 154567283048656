import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

// Hooks
import useAdvanceTable from 'hooks/useAdvanceTable';

// Helpers
import getQueryParams from 'helpers/getQueryParams.hl';

// Providers
import AdvanceTableProvider from 'providers/AdvanceTableProvider';

// Components
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import UsersKindModal from './UsersKindModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaMoneyBillTransfer, FaMoneyBills } from 'react-icons/fa6';
import SubtleBadge from 'components/common/SubtleBadge';

// Constants
import {
  profileKinds,
  profileTypes,
  SUMSUB_REVIEW_STATUSES
} from './constants';

// Types
import { IUser, IUserData } from './types';
import Avatar from 'components/common/Avatar';

type TransactionsTablePropsTypes = {
  pagination: { page: number; limit: number; countAll?: number };
  users?: IUser[];
  handlePageChange: (page: number) => void;
};

type PreparedUser = Omit<IUser, 'kyc_data' | 'kind'> & {
  kyc_data: {
    type: string;
  };
  kind: {
    value: string;
    label: string;
  };
};

const TransactionsTable = (props: TransactionsTablePropsTypes) => {
  const { users, pagination, handlePageChange } = props;
  const [preparedData, setPreparedData] = useState<PreparedUser[]>([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState<IUserData | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const params = getQueryParams(searchParams);

  const handleOpenModal = (action: string, userId: string) => {
    setSearchParams({ ...params, action, userId });
    setModalShow(true);
  };

  const handleShowStatusDetail = (
    action: string,
    userId: string,
    data: IUserData
  ) => {
    setModalData(data);
    handleOpenModal(action, userId);
  };

  const handleCloseModal = () => {
    const copyParams = params;
    delete copyParams.action;
    delete copyParams.userId;
    setSearchParams({ ...copyParams });
    setModalData(null);
    setModalShow(false);
  };

  const columns = [
    {
      accessorKey: 'name',
      header: 'ФИО',
      meta: {
        headerProps: { className: 'ps-3 text-900' },
        cellProps: { className: 'ps-3' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { name, email, phone } = original;
        return (
          <div className="d-flex align-items-center">
            <Avatar size="xl" name={name} className="me-2" />
            <div className="ml-3">
              <h6 className="mb-1">{name}</h6>
              <a href={`mailto:${email}`}>{email}</a>
              <div className="">
                <a href={`tel:${phone}`}>{phone}</a>
              </div>
            </div>
          </div>
        );
      }
    },
    {
      accessorKey: 'kyc_data.type',
      header: 'Тип лица',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'is_manager',
      header: 'Признак управленца',
      meta: {
        headerProps: { className: 'ps-3 text-900' },
        cellProps: { className: 'ps-3' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { userId, data_company, is_manager } = original;
        return (
          <OverlayTrigger
            overlay={
              <Tooltip style={{ position: 'fixed' }} id="tooltip-top">
                {is_manager ? (
                  <ul>
                    {data_company?.map(
                      (company: { companyId: string; name: string }) => (
                        <li
                          className="text-start"
                          key={`${userId}-user-company-${company?.companyId}`}
                        >
                          {company?.name}
                        </li>
                      )
                    )}
                  </ul>
                ) : (
                  '-'
                )}
              </Tooltip>
            }
          >
            <p
              className="text-truncate mb-0 cursor-pointer"
              style={{ maxWidth: '150px' }}
            >
              {is_manager ? 'Посмотеть компании' : '-'}
            </p>
          </OverlayTrigger>
        );
      }
    },
    {
      accessorKey: 'aml_data.status',
      header: 'SumSub',
      meta: {
        headerProps: { className: 'ps-7 text-900' },
        cellProps: { className: 'ps-7' }
      }
    },
    {
      accessorKey: 'aml_data.weight',
      header: 'Вес доверия',
      meta: {
        headerProps: { className: 'ps-2 text-900' },
        cellProps: { className: 'ps-4' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const {
          aml_data: { weight }
        } = original;
        return (
          <OverlayTrigger
            overlay={
              <Tooltip style={{ position: 'fixed' }} id="tooltip-top">
                Описание данного веса доверия
              </Tooltip>
            }
          >
            <p
              className="text-truncate mb-0 user-select-all"
              style={{ maxWidth: '150px' }}
            >
              {weight}
            </p>
          </OverlayTrigger>
        );
      }
    },
    {
      accessorKey: 'kind.label',
      header: 'Статус',
      meta: {
        headerProps: { className: 'ps-7 text-900' },
        cellProps: { className: 'ps-4' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { kind, userId } = original;
        return (
          <SubtleBadge
            pill
            bg={classNames({
              success: kind?.value === 'success',
              primary: kind?.value === 'no-verified',
              warning: kind?.value === 'blocked'
            })}
            onClick={() =>
              kind?.value !== 'success' &&
              handleShowStatusDetail('show', userId, original?.data)
            }
            className={`d-flex px-3 flex-center ${
              kind?.value !== 'success'
                ? 'cursor-pointer hover-outline-700 hover-shadow'
                : ''
            }`}
          >
            <p className="mb-0 h6">{kind?.label}</p>
            <FontAwesomeIcon
              icon={
                classNames({
                  check: kind?.value === 'success',
                  redo: kind?.value === 'no-verified',
                  ban: kind?.value === 'blocked'
                }) as any
              }
              transform="shrink-2"
              className="ms-1"
            />
          </SubtleBadge>
        );
      }
    },
    {
      accessorKey: 'kyc_data.country',
      header: 'Страна регистрации',
      meta: {
        headerProps: { className: 'ps-4 text-900' },
        cellProps: { className: 'ps-6' }
      }
    },
    {
      accessorKey: 'start_date',
      header: 'Дата регистрации',
      meta: {
        headerProps: { className: 'ps-4 text-900' },
        cellProps: { className: 'ps-4' }
      }
    },
    {
      id: 'transactions',
      meta: {
        headerProps: { className: 'ps-2 text-900' },
        cellProps: { className: 'ps-3' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { userId } = original;
        return (
          <OverlayTrigger
            overlay={
              <Tooltip style={{ position: 'fixed' }} id="tooltip-top">
                Транзакции
              </Tooltip>
            }
          >
            <a href={`/transactions?userId=${userId}`}>
              <FaMoneyBillTransfer style={{ width: '30px', height: '30px' }} />
            </a>
          </OverlayTrigger>
        );
      }
    },
    {
      id: 'accounts',
      meta: {
        headerProps: { className: 'ps-2 text-900' },
        cellProps: { className: 'ps-3' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { userId } = original;
        return (
          <OverlayTrigger
            overlay={
              <Tooltip style={{ position: 'fixed' }} id="tooltip-top">
                Счета
              </Tooltip>
            }
          >
            <a href={`/accounts?userId=${userId}`}>
              <FaMoneyBills style={{ width: '30px', height: '30px' }} />
            </a>
          </OverlayTrigger>
        );
      }
    },
    {
      accessorKey: 'kind.value',
      header: 'Действия',
      meta: {
        headerProps: { className: 'ps-4 text-900' },
        cellProps: { className: 'ps-4' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { kind, userId } = original;
        return (
          <Button
            className="d-flex align-items-center justify-content-center w-100"
            variant="primary"
            id="button-addon2"
            onClick={() => handleOpenModal(kind.value, userId)}
          >
            {kind.value === 'blocked' || kind.value === 'no-verified'
              ? 'Разблокировать'
              : 'Заблокировать'}
          </Button>
        );
      }
    }
  ];

  useEffect(() => {
    if (!users?.length) return;
    const result = users?.map((dataItem: IUser) => ({
      ...dataItem,
      aml_data: {
        ...dataItem?.aml_data,
        status: SUMSUB_REVIEW_STATUSES?.[dataItem?.aml_data?.status]
      },
      start_date: dayjs(dataItem?.start_date)?.format('DD.MM.YYYY hh:mm:ss'),
      kyc_data: {
        ...dataItem?.kyc_data,
        type: profileTypes?.[dataItem?.kyc_data?.type]
      },
      kind: { label: profileKinds?.[dataItem?.kind], value: dataItem?.kind }
    }));
    setPreparedData(result);
  }, [users]);

  const table = useAdvanceTable({
    data: preparedData,
    columns,
    sortable: true,
    pagination: true,
    perPage: pagination?.limit,
    page: pagination?.page,
    rowCount: pagination?.countAll,
    setPage: (page: number) => handlePageChange(++page),
    previousPage: (page: number) => handlePageChange(++page),
    nextPage: (page: number) => handlePageChange(++page + 1)
  } as any);

  return (
    <>
      <div className="w-100">
        <AdvanceTableProvider {...table}>
          <Card.Body className="p-0">
            <AdvanceTable
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs-10 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination />
          </Card.Footer>
        </AdvanceTableProvider>
      </div>
      <UsersKindModal
        modalShow={modalShow}
        setModalClose={handleCloseModal}
        data={modalData}
      />
    </>
  );
};

export default TransactionsTable;
