import React, { useState } from 'react';
import {
  Modal,
  Button,
  FormControl,
  Spinner,
  Card,
  Table,
  Accordion
} from 'react-bootstrap';
import IconButton from '../common/IconButton';

const ProfilePage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [loading] = useState(false);

  const handleSearch = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchQuery(event.target.value);
  };

  const handleSubmit = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchQuery(event.target.value);
  };
  const closeDisableModal = () => {
    setShowDisableModal(false);
  };

  // @ts-ignore
  return (
    <div className="ProfilePage-page">
      <div className="w-100 d-flex gap-3 mb-5">
        <div className="w-100">
          <FormControl
            placeholder="Поиск по ФИО, телефону, номеру счёта"
            value={searchQuery}
            onChange={handleSearch}
            className="search-bar"
          />
        </div>
        {/* @ts-ignore */}
        <IconButton
          className="d-flex align-items-center gap-2"
          variant="primary"
          id="button-addon2"
          disabled={loading}
          icon="search"
          iconAlign="left"
          onClick={handleSubmit}
        >
          Поиск
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : null}
        </IconButton>
      </div>

      <Card className="ProfilePage-card mb-3 p-3 rounded-4">
        <div className="d-flex">
          <div className="flex-grow-1">
            <div className="user-info mb-3">
              <p>
                ФИО: {/* @ts-ignore */}
                <input
                  placeholder="______"
                  className="search-bar form-control"
                  style={{ display: 'inline', width: 'auto' }}
                />
              </p>
              <p>
                ДР: {/* @ts-ignore */}
                <input
                  placeholder="______"
                  className="search-bar form-control"
                  style={{ display: 'inline', width: 'auto' }}
                />
              </p>
              <p>
                Адрес: {/* @ts-ignore */}
                <input
                  placeholder="______"
                  className="search-bar form-control"
                  style={{ display: 'inline', width: 'auto' }}
                />
              </p>
              <p>
                Документы: {/* @ts-ignore */}
                <input
                  placeholder="______"
                  className="search-bar form-control"
                  style={{ display: 'inline', width: 'auto' }}
                />
              </p>
              <p>
                Проверка: {/* @ts-ignore */}
                <input
                  placeholder="______"
                  className="search-bar form-control"
                  style={{ display: 'inline', width: 'auto' }}
                />
              </p>
            </div>
          </div>

          <div className="photo-section">
            <div
              className="photo-placeholder bg-light border d-flex justify-content-center align-items-center"
              style={{ height: '210px', width: '160px' }}
            >
              фото
            </div>
          </div>
        </div>
      </Card>

      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Счета (Accounts)</Accordion.Header>
          <Accordion.Body>
            <Card.Body className="rounded-4">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Счёт</th>
                    <th>Валюта</th>
                    <th>Баланс</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Счёт_1</td>
                    <td>USD</td>
                    <td>1000,2</td>
                  </tr>
                  <tr>
                    <td>Счёт_2</td>
                    <td>ETH</td>
                    <td>0,0025</td>
                  </tr>
                  <tr>
                    <td>Счёт_3</td>
                    <td>USDT</td>
                    <td>10,25</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Accordion.Body>
        </Accordion.Item>
        <div className="m-3"></div>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Ограничения (Restrictions)</Accordion.Header>
          <Accordion.Body>
            <Card.Body className="rounded-4">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Ограничение</th>
                    <th>Транзакции</th>
                    <th>Вывод</th>
                    <th>Перевод</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Лимит на день</td>
                    <td>Открытие счета</td>
                    <td>Вывод на внешние</td>
                    <td>Заказ карты</td>
                  </tr>
                  <tr>
                    <td>Лимит на неделю</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Лимит на месяц</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Modal show={showDisableModal} onHide={closeDisableModal}>
        <Modal.Header closeButton>
          <Modal.Title>Выберите аккаунты для отключения</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Список аккаунтов для отключения</p>
          {/* Add accounts list here */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDisableModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProfilePage;
