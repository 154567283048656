import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { FiltersTypes } from '../components/transactions/types';

class ApiService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.axiosInstance.get(url, config).then();
  }

  public fetchTransactions<ITransactionResponse>(
    filters: FiltersTypes,
    requestId: string
  ): Promise<ITransactionResponse> {
    return this.axiosInstance
      .get<ITransactionResponse>(`/operations/v1/get/`, {
        headers: {
          requestId
        },
        params: filters
      })
      .then(response => response.data);
  }

  public post<T>(
    url: string,
    data: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.axiosInstance
      .post(url, data, config)
      .then(response => response.data);
  }

  public searchAccounts(params: {
    search: string;
    currency?: string;
    dateFrom?: string;
    dateTo?: string;
    page: number;
    limit: number;
  }): any {
    return this.axiosInstance.get('/account/v1/search', { params });
  }

  public getTransactions(
    accountId: string,
    params: {
      page: number;
      limit: number;
    }
  ): any {
    return this.axiosInstance.get(`/account/v1/${accountId}/transactions`, {
      params
    });
  }
}

export default new ApiService();
