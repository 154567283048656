const activeUserReasonsData = {
  code: 'ERCD0000',
  message: 'Запрос обработан успешно',
  data: [
    {
      id: 'bae4dfba-5214-48db-8e94-bcca7429cd77',
      description: 'Referral program'
    },
    {
      id: '3c668728-44db-49f9-ad0c-e346b21ef405',
      description: 'User requested activation'
    },
    {
      id: '9d849e3a-21ba-437f-b8d0-3b54133770f7',
      description: 'New feature release'
    },
    {
      id: '97044b20-a2e8-484a-9ff8-759c82d58f9d',
      description: 'Referral program'
    },
    {
      id: 'cfa563ef-a4cb-448c-bea5-6274774d218e',
      description: 'Referral program'
    },
    {
      id: 'b6835f9e-09d2-40f8-9e00-1df2f556ae71',
      description: 'Inactive for a period'
    },
    {
      id: '6dd5a43d-d5e9-4182-b93c-db52119f03bb',
      description: 'Special promotion'
    },
    {
      id: '3e4aee8a-2dc5-4844-af59-765a1f77d5c1',
      description: 'User requested activation'
    },
    {
      id: '2cb1e922-cf84-4a84-a0e1-cee1243bba3e',
      description: 'New feature release'
    },
    {
      id: '3fcabfdf-3e5d-4884-b7b3-ee5376b45f43',
      description: 'New feature release'
    }
  ]
};

export default activeUserReasonsData;
