import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';

// Hooks
import useAdvanceTable from 'hooks/useAdvanceTable';

// Providers
import AdvanceTableProvider from 'providers/AdvanceTableProvider';

// Components
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaRegCopy } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SubtleBadge from 'components/common/SubtleBadge';

// Constants
import { TRANSACTION_STATUSES, TRANSACTION_TYPES } from './constants';
import { CURRENCIES } from 'common/constants';

// Types
import { ITransaction } from './types';

// Images
import currencies from 'assets/img/icons/currencies';

type TransactionsTablePropsTypes = {
  pagination: { page: number; limit: number; countAll?: number };
  transactions?: ITransaction[];
  handlePageChange: (page: number) => void;
};

const TransactionsTable = (props: TransactionsTablePropsTypes) => {
  const { transactions, pagination, handlePageChange } = props;
  const [copiedId, setCopiedId] = useState('');
  const [preparedData, setPreparedData] = useState<ITransaction[]>([]);

  const handleCopy = (data: string) => {
    navigator.clipboard.writeText(data);
    setCopiedId(data);
    setTimeout(() => {
      setCopiedId('');
    }, 1500);
  };

  const columns = [
    {
      accessorKey: 'type',
      header: 'Oперация',
      meta: {
        headerProps: { className: 'text-900 ps-3 py-2' },
        cellProps: { className: 'ps-4 py-2' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { type } = original;
        return <span>{TRANSACTION_TYPES?.[type]}</span>;
      }
    },
    {
      accessorKey: 'accountOut',
      header: 'Отправитель',
      meta: {
        headerProps: { className: 'ps-4 text-900' },
        cellProps: { className: 'ps-4' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { accountOut } = original;
        return (
          <OverlayTrigger
            overlay={
              <Tooltip
                show={copiedId === accountOut}
                style={{ position: 'fixed' }}
                id="tooltip-top"
              >
                {copiedId === accountOut ? 'Скопировано' : accountOut}
              </Tooltip>
            }
          >
            <div
              style={{ maxWidth: '100px' }}
              className="d-flex align-items-center justify-content-between"
            >
              <a
                className="text-truncate mb-0 user-select-all w-75"
                href={`/accounts?search=${accountOut}`}
              >
                {accountOut}
              </a>
              <FaRegCopy role="button" onClick={() => handleCopy(accountOut)} />
            </div>
          </OverlayTrigger>
        );
      }
    },
    {
      accessorKey: 'accountIn',
      header: 'Получатель',
      meta: {
        headerProps: { className: 'text-900 ps-4' },
        cellProps: { className: 'ps-4' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { accountIn } = original;
        return (
          <OverlayTrigger
            overlay={
              <Tooltip
                show={copiedId === accountIn}
                style={{ position: 'fixed' }}
                id="tooltip-top"
              >
                {copiedId === accountIn ? 'Скопировано' : accountIn}
              </Tooltip>
            }
          >
            <div
              style={{ maxWidth: '100px' }}
              className="d-flex align-items-center justify-content-between"
            >
              <a
                className="text-truncate mb-0 user-select-all w-75"
                href={`/accounts?search=${accountIn}`}
              >
                {accountIn}
              </a>
              <FaRegCopy role="button" onClick={() => handleCopy(accountIn)} />
            </div>
          </OverlayTrigger>
        );
      }
    },
    {
      accessorKey: 'amount',
      header: 'Сумма',
      meta: {
        headerProps: { className: 'ps-4 text-900' },
        cellProps: { className: 'ps-4' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { amount, type } = original;
        console.log('type: ', type);
        return (
          <span style={type === 1 ? { color: 'green' } : { color: 'red' }}>
            {type !== 1 ? '-' : ''}
            {amount}
          </span>
        );
      }
    },
    {
      accessorKey: 'currency',
      header: 'Валюта',
      meta: {
        headerProps: { className: 'text-900 ps-3 py-2' },
        cellProps: { className: 'ps-4 py-2' }
      },
      cell: ({
        row: { original }
      }: {
        row: { original: { currency: any } };
      }) => {
        const { currency } = original;
        return (
          <OverlayTrigger
            overlay={
              <Tooltip style={{ position: 'fixed' }} id="tooltip-top">
                {currency}
              </Tooltip>
            }
          >
            <img
              style={{ height: '30px' }}
              src={(currencies as any)?.[CURRENCIES?.[currency]]}
            ></img>
          </OverlayTrigger>
        );
      }
    },
    {
      accessorKey: 'rate',
      header: 'Курс',
      meta: {
        headerProps: { className: 'ps-4 text-900' },
        cellProps: { className: 'ps-4' }
      }
    },
    {
      accessorKey: 'fee',
      header: 'Комиссия',
      meta: {
        headerProps: { className: 'ps-2 text-900' },
        cellProps: { className: 'ps-4' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { fee } = original;
        return <span>{fee}%</span>;
      }
    },
    {
      accessorKey: 'kind',
      header: 'Статус',
      meta: {
        headerProps: { className: 'text-900 ps-4' },
        cellProps: { className: 'ps-4' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { kind } = original;
        return (
          <SubtleBadge
            pill
            bg={classNames({
              success: kind === 2,
              primary: kind === 1,
              warning: kind === 3
            })}
            className="d-flex flex-center"
          >
            <p className="mb-0 h6">{TRANSACTION_STATUSES?.[kind]}</p>
            <FontAwesomeIcon
              icon={
                classNames({
                  check: kind === 2,
                  redo: kind === 1,
                  ban: kind === 3
                }) as any
              }
              transform="shrink-2"
              className="ms-1"
            />
          </SubtleBadge>
        );
      }
    },
    {
      accessorKey: 'start_date',
      header: 'Время начала операции',
      meta: {
        headerProps: { className: 'ps-4 text-900' },
        cellProps: { className: 'ps-4' }
      }
    },
    {
      accessorKey: 'update_date',
      header: 'Время обновления',
      meta: {
        headerProps: { className: 'ps-4 text-900' },
        cellProps: { className: 'ps-4' }
      }
    },
    {
      accessorKey: 'description',
      header: 'Комментарий',
      meta: {
        headerProps: { className: 'ps-4 text-900' },
        cellProps: { className: 'ps-4' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { description } = original;
        return (
          <OverlayTrigger
            overlay={
              <Tooltip style={{ position: 'fixed' }} id="tooltip-top">
                {description}
              </Tooltip>
            }
          >
            <p
              className="text-truncate mb-0 user-select-all"
              style={{ maxWidth: '150px' }}
            >
              {description}
            </p>
          </OverlayTrigger>
        );
      }
    }
  ];

  useEffect(() => {
    if (!transactions?.length) return;
    const result = transactions?.map((dataItem: ITransaction) => ({
      ...dataItem,
      start_date: dayjs(dataItem?.start_date)?.format('DD.MM.YYYY hh:mm:ss'),
      update_date: dayjs(dataItem?.update_date)?.format('DD.MM.YYYY hh:mm:ss')
    }));
    setPreparedData(result);
  }, [transactions]);

  const table = useAdvanceTable({
    data: preparedData,
    columns,
    sortable: true,
    pagination: true,
    perPage: pagination?.limit,
    page: pagination?.page,
    rowCount: pagination?.countAll,
    setPage: (page: number) => handlePageChange(++page),
    previousPage: (page: number) => handlePageChange(++page),
    nextPage: (page: number) => handlePageChange(++page + 1)
  } as any);

  return (
    <div className="w-100">
      <AdvanceTableProvider {...table}>
        <Card.Body className="p-0">
          <AdvanceTable
            headerClassName="bg-200 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs-10 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination />
        </Card.Footer>
      </AdvanceTableProvider>
    </div>
  );
};

export default TransactionsTable;
