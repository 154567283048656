import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';

// Components
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import Select from 'react-select';

// Constants
import { actionKinds } from './constants';

// Helpers
import getQueryParams from 'helpers/getQueryParams.hl';

// Data
import blockUserReasonsData from 'data/blockUserReasons';
import activeUserReasonsData from 'data/activeUserReasons';

// Types
import { IUserData } from './types';

type UsersKindModalPropsTypes = {
  modalShow: boolean;
  setModalClose: () => void;
  data?: IUserData | null;
};

const UsersKindModal = (props: UsersKindModalPropsTypes) => {
  const { modalShow, setModalClose, data } = props;
  const [reasonsOptions, setReasonsOptions] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const params = getQueryParams(searchParams);

  const getActionReasons = async (action: string) => {
    action;
    const response = await (action === 'blocked'
      ? activeUserReasonsData
      : blockUserReasonsData);
    setReasonsOptions(
      response?.data?.map(reason => ({
        value: reason?.id,
        label: reason?.description
      }))
    );
    setLoading(false);
  };

  const handlerSubmit = async () => {
    setLoading(true);
    params?.userId;
    // Request to update user
    resetForm();
    searchParams.delete('action');
    searchParams.delete('userId');
    setModalClose();
  };

  const handleClose = () => {
    resetForm();
    setModalClose();
  };

  const {
    handleSubmit,
    setFieldValue,
    setValues,
    values: { reason, description },
    resetForm
  } = useFormik({
    initialValues: {
      description: ''
    } as {
      reason?: { value?: string; label?: string };
      description?: string;
    },
    onSubmit: handlerSubmit
  });

  useEffect(() => {
    if (params?.action === 'show') {
      setValues({
        ...data,
        reason: {
          value: data?.reason?.id,
          label: data?.reason?.description
        }
      });
    } else {
      setLoading(true);
      getActionReasons(params?.action);
    }
  }, [params?.action]);

  return (
    <Modal
      show={modalShow}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {actionKinds?.[params?.action]}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formGroupName">
            <Form.Label>Причина</Form.Label>
            <Select
              closeMenuOnSelect={false}
              options={reasonsOptions as any}
              placeholder="Выбери причину"
              isDisabled={loading || params?.action === 'show'}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  maxHeight: state?.hasValue ? 'unset' : '36px'
                })
              }}
              className="w-100"
              classNamePrefix="react-select"
              value={reason}
              onChange={value => setFieldValue('reason', value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Коментарий</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              value={description}
              onChange={e => setFieldValue('description', e?.target?.value)}
              disabled={loading || params?.action === 'show'}
              name="description"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      {params?.action === 'show' ? null : (
        <Modal.Footer>
          <Button
            className="d-flex align-items-center gap-2"
            disabled={loading}
            onClick={handleSubmit as any}
          >
            {actionKinds?.[params?.action]}
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default UsersKindModal;
