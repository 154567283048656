import React, { useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';

interface Operator {
  name: string;
  profileType: string;
  group: string;
  enabled: boolean;
  roles: string[];
}

const ProfilesList: React.FC = () => {
  const [operators, setOperators] = useState<Operator[]>([]);
  const [selectedOperator, setSelectedOperator] = useState<Operator | null>(
    null
  );
  const [showForm, setShowForm] = useState(false);
  const [tab, setTab] = useState('roles'); // To toggle between 'roles' and 'groups'

  // Show form to add or edit an operator
  const addOperator = () => {
    setSelectedOperator(null);
    setShowForm(true);
  };

  const editOperator = (operator: Operator) => {
    setSelectedOperator(operator);
    setShowForm(true);
  };

  const deleteOperator = (operatorToDelete: Operator) => {
    if (
      window.confirm(`Вы уверены, что хотите удалить ${operatorToDelete.name}?`)
    ) {
      setOperators(operators.filter(op => op !== operatorToDelete));
    }
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const name = (form.elements.namedItem('name') as HTMLInputElement).value;
    const profileType = (
      form.elements.namedItem('profileType') as HTMLInputElement
    ).value;
    const group = (form.elements.namedItem('group') as HTMLSelectElement).value;
    const roles = Array.from(
      form.querySelectorAll(
        'input[name="roles"]:checked'
      ) as NodeListOf<HTMLInputElement>
    ).map(input => input.value);
    const enabled = (form.elements.namedItem('enabled') as HTMLInputElement)
      .checked;

    const newOperator: Operator = {
      name,
      profileType,
      group,
      enabled,
      roles
    };

    if (selectedOperator) {
      // Update existing operator
      setOperators(
        operators.map(op => (op === selectedOperator ? newOperator : op))
      );
    } else {
      // Add new operator
      setOperators([...operators, newOperator]);
    }

    setShowForm(false);
  };

  return (
    <div className="profiles-page" style={{ padding: '20px' }}>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Справочники</h2>
      </div>

      {/* Tab buttons */}
      <div className="mb-4">
        <Button
          variant={tab === 'roles' ? 'primary' : 'secondary'}
          onClick={() => setTab('roles')}
        >
          Роли
        </Button>
        <Button
          variant={tab === 'groups' ? 'primary' : 'secondary'}
          onClick={() => setTab('groups')}
        >
          Группы
        </Button>
      </div>

      {/* Conditional rendering based on selected tab */}
      {tab === 'roles' && !showForm && (
        <div
          style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px'
          }}
        >
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Наименование</th>
                <th>Тип профиля</th>
                <th>Группа</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Создание профиля</td>
                <td>Фин. контролёр</td>
                <td></td>
              </tr>
              {operators.map((operator, index) => (
                <tr key={index}>
                  <td>{operator.name}</td>
                  <td>{operator.profileType}</td>
                  <td>{operator.group}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      {tab === 'groups' && !showForm && (
        <div
          style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px'
          }}
        >
          {/* Add button aligned to the right */}
          <div className="d-flex justify-content-end mb-4">
            <Button variant="primary" onClick={addOperator}>
              Добавить группу
            </Button>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Наименование группы</th>
                <th>Список ролей</th>
                <th>Участники</th>
                <th>Управление</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Создание профиля</td>
                <td>Фин. контролёр</td>
                <td></td>
                <td>
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={() => editOperator(operators[0])}
                  >
                    ✏️
                  </Button>{' '}
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => deleteOperator(operators[0])}
                  >
                    🗑️
                  </Button>
                </td>
              </tr>
              {operators.map((operator, index) => (
                <tr key={index}>
                  <td>{operator.name}</td>
                  <td>{operator.roles.join(', ')}</td>
                  <td>{operator.group}</td>
                  <td>
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={() => editOperator(operator)}
                      style={{ marginRight: '5px' }}
                    >
                      ✏️
                    </Button>
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={() => deleteOperator(operator)}
                    >
                      🗑️
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      {showForm && (
        <div
          style={{
            backgroundColor: '#e7f3ff',
            padding: '20px',
            borderRadius: '8px',
            marginTop: '20px'
          }}
        >
          <h4>
            {selectedOperator ? 'Редактировать группу' : 'Добавить группу'}
          </h4>
          <Form onSubmit={handleFormSubmit}>
            <Row>
              <Col>
                <Form.Group controlId="groupName">
                  <Form.Label>Название группы</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    defaultValue={selectedOperator ? selectedOperator.name : ''}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group controlId="roles">
                  <Form.Label>Роли</Form.Label>
                  <div>
                    {[
                      'Добавление Оператора',
                      'Добавление Оператора',
                      'Добавление Оператора'
                    ].map(role => (
                      <Form.Check
                        key={role}
                        type="checkbox"
                        name="roles"
                        value={role}
                        label={role}
                        defaultChecked={selectedOperator?.roles.includes(role)}
                      />
                    ))}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-3">
              <Button
                variant="secondary"
                onClick={() => setShowForm(false)}
                className="me-2"
              >
                Отмена
              </Button>
              <Button variant="primary" type="submit">
                Сохранить
              </Button>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

export default ProfilesList;
